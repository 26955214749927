// src/redux/productSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../gobal/Global';
import axios from 'axios';

// Async action to fetch data
export const fetchProductData = createAsyncThunk(
    'product/fetchProductData',
    async ({ page = 1 }, thunkAPI) => {
      const response = await axios.get(`${API_URL}/product?page=${page}`);
      return response.data; // This assumes response.data contains { categories: [...] }
    }
);

export const fetchProductBySubCategory = createAsyncThunk(
    'product/fetchProductBySubCategory',
    async (subCategoryId) => {
      const response = await axios.get(`${API_URL}/product-by-subcategory/${subCategoryId}`);
      return response.data;
    }
);

export const fetchCategoriesData = createAsyncThunk('setup/fetchCategoriesData', async () => {
  const response = await axios.get(`${API_URL}/category-list`);
  return response.data;
});

export const fetchProductByCategoryId = createAsyncThunk(
    'product/fetchProductByCategory',
    async (categoryId) => {
      const response = await axios.get(`${API_URL}/product-by-category/${categoryId}`);      
      return response.data;
    }
);

// Create a slice
const productSlice = createSlice({
  name: 'product',
  initialState: {
    categories: [],
    brands: [],
    products: {}, // Adjusting to reflect the structure you are returning
    seoSetting: {},
    loading: true,
    error: null,
  },
  reducers: {
    // New reducer to update products
    updateProducts: (state, action) => {
      state.products.data = action.payload; // Update the products state with the new data
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchProductData.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchProductData.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.brands = payload.brands || [];
          state.products = payload.products || [];
          state.seoSetting = payload.seoSetting || {};
        })
        .addCase(fetchProductData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(fetchProductBySubCategory.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchProductBySubCategory.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.products.data = payload.products || [];
        })
        .addCase(fetchProductBySubCategory.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(fetchCategoriesData.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchCategoriesData.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.categories = payload.categories || [];
        })
        .addCase(fetchCategoriesData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(fetchProductByCategoryId.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchProductByCategoryId.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.products.data = payload.products || [];
        })
        .addCase(fetchProductByCategoryId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
  },
});

// Export the new action
export const { updateProducts } = productSlice.actions;

export default productSlice.reducer;
