import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCart, cartItemDecrement, cartItemIncrement, fetchAddToCart, removeFromCart } from '../redux/slice/addToCartSlice';
import { showConfirm, showError } from '../gobal/sweetalertManage';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useAddToCart = () => {
    const dispatch = useDispatch();
    const { addToCart } = useSelector(state => state.addToCart);
    const { isLoggedIn } = useSelector(state => state.auth);
    const navigate = useNavigate();

    // Initialize cart items when logged in
    const fetchCart = useCallback(() => {
        if (isLoggedIn) {
            dispatch(fetchAddToCart());
        } else {
            navigate("/login");
        }
    }, [isLoggedIn, dispatch, navigate]);

    const isProductInCartlist = (productId) => {
        const findCartId = addToCart?.find(item => item?.product.id === productId);
        
        // console.log("findCartIdfindCartIdfindCartIdfindCartId",findCartId,addToCart);

        return findCartId ? findCartId.id : null; // Safe handling
    };

    const handleAddToCart = async (productId) => {
        try {
            const cartId = isProductInCartlist(productId);
            if (cartId) {
                showError("Product already in the cart");
                return;
            }
            await dispatch(addCart({ product_id: productId, quantity: 1 })).unwrap();
            showConfirm("Item added successfully");
        } catch (error) {
            showError(error.message || "Failed to add to cart");
        }
    };

    const handleRemoveCart = async (cartId) => {
        try {
            await dispatch(removeFromCart(cartId)).unwrap();
            toast.success( "Removed Successfully!");
        } catch (error) {
            toast.error(error.message || "Failed to remove!");

        }
    };

    const handleCartListToggle = async (productId) => {
        if(isLoggedIn){
            const cartId = isProductInCartlist(productId);
            if (cartId) {
                handleRemoveCart(cartId);
            } else {
                handleAddToCart(productId);
            }
        }else{
            navigate('/login');
        }
    };

    // Increment cart quantity by cart ID
    const incrementQty = async (cartId) => {
        try {
            const response =  await dispatch(cartItemIncrement(cartId)).unwrap();
            toast.success(response.message);
            fetchCart();
        } catch (error) {
            toast.error(error.message || "Failed to increment!");
        }
    };

    // Decrement cart quantity by cart ID
    const decrementQty = async (cartId) => {
        try {
            const response = await dispatch(cartItemDecrement(cartId)).unwrap();
            toast.success(response.message);
            fetchCart();
        } catch (error) {
            toast.error("You cannot decrement the quantity below 1!");   
        }
    };

    return {
        isProductInCartlist,
        handleCartListToggle,
        incrementQty,
        decrementQty,
        handleRemoveCart,
        fetchCart
    };
};
