import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';


// Define the initial state for home data
const initialState = {
    shippingPolicy: null,  // Store blogs
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    loading: false,
    error: null,
};


// Create async thunk for fetching home data
export const fetchShippingPolicyData = createAsyncThunk('shippingPolicy/fetchShippingPolicyData', async () => {
    const response = await axios.get(`${API_URL}/shipping-policy`);
    return response.data;
});


// Create the slice
const shippingPolicySlice = createSlice({
    name: 'shippingPolicy',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShippingPolicyData.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchShippingPolicyData.fulfilled, (state, action) => {
                state.shippingPolicy = action.payload.shippingPolicy;
                state.status = 'succeeded';
                state.loading = false;
            })
            .addCase(fetchShippingPolicyData.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the async thunk and the reducer
export default shippingPolicySlice.reducer;