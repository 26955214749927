import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';


// Define the initial state for home data
const initialState = {
    returnPolicy: null,  // Store blogs
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    loading: false,
    error: null,
};


// Create async thunk for fetching home data
export const fetchReturnPolicyData = createAsyncThunk('returnPolicy/fetchReturnPolicyData', async () => {
    const response = await axios.get(`${API_URL}/return-policy`);
    // console.log("Return___", response.data);
    return response.data;
});


// Create the slice
const returnPolicySlice = createSlice({
    name: 'returnPolicy',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReturnPolicyData.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchReturnPolicyData.fulfilled, (state, action) => {
                state.returnPolicy = action.payload.returnPolicy;
                state.status = 'succeeded';
                state.loading = false;
            })
            .addCase(fetchReturnPolicyData.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the async thunk and the reducer
export default returnPolicySlice.reducer;