import React, {useEffect} from 'react'
import Slider from '../elements/home/Slider'
import IconBox from '../elements/home/IconBox'
import HotDeal from '../elements/home/HotDeal'
import Categories from '../elements/home/Categories'
import Campaign from '../elements/home/Campaign'
import Seller from '../elements/home/Seller'
import Testimonial from '../elements/home/Testimonial'
import Brands from '../elements/Brands'
import {useDispatch, useSelector} from "react-redux";
import {fetchHomeData} from "../../redux/slice/homeSlice";
import { fetchFlashSale } from '../../redux/slice/flashSaleSlice'
import FlashSale from '../elements/home/FlashSale'
import { Helmet } from 'react-helmet'



export default function Home() {

    const dispatch = useDispatch()

    const { 
      seoSetting,
      sliderVisibility, 
      sliders, 
      homepageCategories, 
      newArrivalProducts, 
      bestProducts, 
      bestProductVisibility, 
      brandVisibility 
    } = useSelector(state => state.home)
    
    const {
      flashSale, 
      products
    } = useSelector(state => state.flashSale);

    useEffect(() => {
        dispatch(fetchHomeData());
        dispatch(fetchFlashSale());
    }, []);
  console.log("Flash Sale__",flashSale);
  return (
    <React.Fragment>
        <Helmet>
          <meta property="og:title" content={seoSetting?.page_name} />
          <meta property="og:description" content={seoSetting?.seo_description} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        { sliderVisibility ?<Slider sliders={sliders}/>:<></> }
        <IconBox/>
        <HotDeal newArrivalProducts={newArrivalProducts} title="New Arrivals"/>
        { flashSale?.status !== 0 ? <FlashSale sale={flashSale}/> : <></> }
        <Categories homepageCategories={homepageCategories}/>
        <Campaign />
        { bestProductVisibility?<Seller title="Best Products" bestProducts={bestProducts} />:<></> }
        <Testimonial/>
        { brandVisibility ? <Brands/>:<></> }
    </React.Fragment>
  )
}
