import React, {useEffect} from 'react'
import InnerBanner from '../elements/InnerBanner'
import { NavLink } from 'react-router-dom'
import ContactForm from '../elements/ContactForm'
import {useDispatch, useSelector} from "react-redux";
import {fetchContactDetails} from "../../redux/slice/contactSlice";
import Preloader from '../include/Preloader'

export default function Contact() {
    const dispatch = useDispatch()
    const {contact,loading} = useSelector((state) => state.contact);
    // console.log(contact)
    useEffect(()=>{
        dispatch(fetchContactDetails())
    },[dispatch])
    if(loading){
        return <Preloader/>
    }
    // console.log("Contact__",contact.map);
    // console.log("Map__","https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.2146263454815!2d77.1229752348877!3d28.871045399999986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390daba2b6255555%3A0xea3993432e12c132!2sSoftech%20Solutions!5e1!3m2!1sen!2sin!4v1734089044177!5m2!1sen!2sin");
    return (
        <React.Fragment>
            <InnerBanner title="Contact"/>
        {loading?<Preloader/>:     
            <section className="flat-spacing-9">
                <div className="container">
                    <div className="tf-grid-layout gap-0 lg-col-2">
                        <div className="w-100">
                            <iframe 
                                title="India"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.2146263454815!2d77.1229752348877!3d28.871045399999986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390daba2b6255555%3A0xea3993432e12c132!2sSoftech%20Solutions!5e1!3m2!1sen!2sin!4v1734089044177!5m2!1sen!2sin"
                                width="100%" 
                                height="100%" 
                                style={{border: "0"}}
                                allowFullScreen="" 
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                        <div className="tf-content-left has-mt">
                            <div className="sticky-top">
                                <h5 className="mb_20">{contact.title}</h5>
                                <div className="mb_20">
                                    <p className="mb_15"><strong>Address</strong></p>
                                    <p>{contact.address}</p>
                                </div>
                                <div className="mb_20">
                                    <p className="mb_15"><strong>Phone</strong></p>
                                    <p>{contact.phone}</p>
                                </div>
                                <div className="mb_20">
                                    <p className="mb_15"><strong>Email</strong></p>
                                    <p>{contact.email}</p>
                                </div>
                                <div className="mb_36">
                                    <p className="mb_15"><strong>Open Time</strong></p>
                                    <p className="mb_15">Our store has re-opened for shopping, </p>
                                    <p>exchange Every day 11am to 7pm</p>
                                </div>
                                <div>
                                    <ul className="tf-social-icon d-flex gap-20 style-default">
                                        <li><NavLink to="#" className="box-icon link round social-facebook border-line-black"><i className="icon fs-14 icon-fb"></i></NavLink></li>
                                        <li><NavLink to="#" className="box-icon link round social-twiter border-line-black"><i className="icon fs-12 icon-Icon-x"></i></NavLink></li>
                                        <li><NavLink to="#" className="box-icon link round social-instagram border-line-black"><i className="icon fs-14 icon-instagram"></i></NavLink></li>
                                        <li><NavLink to="#" className="box-icon link round social-tiktok border-line-black"><i className="icon fs-14 icon-tiktok"></i></NavLink></li>
                                        <li><NavLink to="#" className="box-icon link round social-pinterest border-line-black"><i className="icon fs-14 icon-pinterest-1"></i></NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        

            {/* start form  */}
            <ContactForm/>
            {/* end form  */}
        </React.Fragment>
    )
}
