import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InnerBanner from '../elements/InnerBanner';
import Preloader from '../include/Preloader';
import { fetchReturnPolicyData } from '../../redux/slice/returnPolicySlice';

export default function ReturnPolicy() {

    const dispatch = useDispatch();
    const { returnPolicy, loading } = useSelector((state) => state.returnPolicy);

    useEffect(() => {
        dispatch(fetchReturnPolicyData());
    }, []);

    console.log("shipping__",returnPolicy);

    if(loading) {
        return <Preloader/>
    }

    return (
        <React.Fragment>
            <InnerBanner title="Return Policy"/>

            <section className="flat-spacing-25">
                <div className="container">
                    <div className="tf-main-area-page"  dangerouslySetInnerHTML={{ __html: returnPolicy?.return_policy }}></div>
                </div>
            </section>
        </React.Fragment>
    )
}
