import React, {useEffect} from 'react'
import InnerBanner from '../elements/InnerBanner'
import Testimonial from '../elements/home/Testimonial'
import {Container} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {fetchAboutData} from "../../redux/slice/aboutSlice";
import {BASE_URL} from "../../gobal/Global";
import { Helmet } from 'react-helmet';

export default function About() {
    const dispatch = useDispatch()
    const {aboutUs, seoSetting } = useSelector(state => state.about)
    useEffect(() => {
        dispatch(fetchAboutData());
    }, [useDispatch])
    
    
    return (
        <React.Fragment>
            <Helmet>
                <meta property="og:title" content={seoSetting?.page_name} />
                <meta property="og:description" content={seoSetting?.seo_description} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <InnerBanner title="About us"/>

            <section className="flat-spacing-9">
                <div className="container">
                    <div className="flat-title my-0">
                        <span className="title">We are Apoorva</span>
                        <p className="sub-title text_black-2">
                            We specialize in providing a wide range of cutting-edge technology, including PCs, laptops,
                            cameras, and fire equipment. Whether you're upgrading your home office, enhancing your
                            security systems, or outfitting your business with essential tech, our collection offers the
                            best in performance and durability. Explore our versatile range of products designed to meet
                            the demands of modern-day technology enthusiasts and professionals alike.
                        </p>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="line"></div>
            </div>

            <section className="flat-spacing-23 flat-image-text-section">
                <div className="container">
                    <div className="tf-grid-layout md-col-2 tf-img-with-text style-4">
                        <div className="tf-image-wrap">
                            <img className="lazyload w-100" data-src={`${BASE_URL + "/" + aboutUs.banner_image}`}
                                 src={`${BASE_URL + "/" + aboutUs.banner_image}`} alt="collection-img"/>
                        </div>
                        <div className="tf-content-wrap px-0 d-flex justify-content-center w-100">
                            <div>
                                {/*<div className="heading">Established - 1995</div>*/}
                                <div className="text" dangerouslySetInnerHTML={{__html: aboutUs.about_us}}/>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="flat-spacing-15">
                <div className="container">
                    <div className="tf-grid-layout md-col-2 tf-img-with-text style-4">
                        <div className="tf-content-wrap px-0 d-flex justify-content-center w-100">
                            <div>
                                <div className="heading">Our mission</div>
                                <div className="text">
                                    At Apoorva Infotech Solutions Pvt. Ltd., our mission is to empower businesses with innovative and reliable IT solutions. Since 2005, we have been committed to delivering tailored technology services that simplify complexities and drive success.  <br className='d-xl-block d-none'/>
                                    We aim to bridge the gap between technology and business growth by providing seamless IT infrastructure, security solutions, and accessible e-commerce services. With a focus on trust, integrity, and customer satisfaction, we continuously enhance our expertise to stay ahead of industry trends. Our goal is to transform challenges into opportunities, ensuring our clients thrive in an ever-evolving digital world.
                                </div>
                            </div>
                        </div>
                        <div className="grid-img-group">
                            <div className="tf-image-wrap box-img item-1">
                                <div className="img-style">
                                    <img className="lazyload" src="assets/images/collections/collection-71.jpg"
                                         data-src="assets/images/collections/collection-71.jpg" alt="img-slider"/>
                                </div>
                            </div>
                            <div className="tf-image-wrap box-img item-2">
                                <div className="img-style">
                                    <img className="lazyload" src={BASE_URL + "/" + aboutUs.image_two}
                                        data-src={BASE_URL + "/" + aboutUs.image_two} alt="img-slider"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="bg_grey-2 radius-10 flat-wrap-iconbox">
                        <div className="flat-title lg">
                            <span className="title fw-5">Quality is our priority</span>
                            <div>
                                <p className="sub-title text_black-2">Our talented stylists have put together outfits
                                    that are perfect for the season.</p>
                                <p className="sub-title text_black-2">They've variety of ways to inspire your next
                                    fashion-forward look.</p>
                            </div>
                        </div>
                        <div className="flat-iconbox-v3 lg">
                            <div className="wrap-carousel wrap-mobile">
                                <div className="swiper tf-sw-mobile" data-preview="1" data-space="15">
                                    <div className="swiper-wrapper wrap-iconbox lg">
                                        <div className="swiper-slide">
                                            <div className="tf-icon-box text-center">
                                                <div className="icon">
                                                    <i className={aboutUs.icon_one}></i>
                                                </div>
                                                <div className="content">
                                                    <div className="title">{aboutUs.title_one}</div>
                                                    <p className="text_black-2">
                                                        {aboutUs.description_one}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tf-icon-box text-center">
                                                <div className="icon">
                                                    <i className={aboutUs.icon_two}></i>
                                                </div>
                                                <div className="content">
                                                    <div className="title">{aboutUs.title_two}</div>
                                                    <p className="text_black-2">
                                                        {aboutUs.description_two}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tf-icon-box text-center">
                                                <div className="icon">
                                                    <i className={aboutUs.icon_three}></i>
                                                </div>
                                                <div className="content">
                                                    <div className="title">{aboutUs.title_three}</div>
                                                    <p className="text_black-2">
                                                        {aboutUs.description_three}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="sw-dots style-2 sw-pagination-mb justify-content-center"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="flat-testimonial-v2 flat-spacing-24">
                <Container>
                    <Testimonial/>
                </Container>
            </section>
        </React.Fragment>
    )
}
