import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchReturnPolicyData } from '../../../redux/slice/returnPolicySlice';

export default function DeliveryReturn() {

    const dispatch = useDispatch();
    const { footer } = useSelector((state) => state.setup);
    const { returnPolicy, loading } = useSelector((state) => state.returnPolicy);

    useEffect(() => {
        dispatch(fetchReturnPolicyData());
    },[]);
    
    return (
        <div className="modal modalCentered fade modalDemo tf-product-modal modal-part-content" id="delivery_return">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="header">
                        <div className="demo-title">Return Policy</div>
                        <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                    </div>
                    <div className="overflow-y-auto">
                        <div className="" dangerouslySetInnerHTML={{ __html: returnPolicy?.return_policy }}></div>
                        <div className="tf-product-popup-delivery">
                            <div className="title">Help</div>
                            <p className="text-paragraph">Give us a shout if you have any other questions and/or concerns.</p>
                            <p className="text-paragraph">Email: <NavLink to={`mailto:${footer?.email}`}
                                    aria-describedby="a11y-external-message"><span
                                        className="__cf_email__">{footer?.email}</span></NavLink></p>
                            <p className="text-paragraph mb-0">Phone: {footer?.phone}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
