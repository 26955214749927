import React, { useEffect, useState } from "react";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/include/Header";
import Footer from "./components/include/Footer";
import Preloader from "./components/include/Preloader";
import GoTop from "./components/include/GoTop";
import TopNavbar from "./components/include/TopNavbar";
import CanvaSearch from "./components/elements/models/CanvaSearch";
import ShoppingCart from "./components/elements/models/ShoppingCart";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import MobileMenu from "./components/elements/models/MobileMenu";
import Profile from "./components/elements/models/Profile";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Blog from "./components/pages/Blog";
import Contact from "./components/pages/Contact";
import HomeSearch from "./components/pages/HomeSearch";
import LoginPage from "./components/pages/LoginPage";
import ForgotPage from "./components/pages/ForgotPage";
import VerifyMail from "./components/pages/VerifyMail";
import RegisterPage from "./components/pages/RegisterPage";
import TermAndCondition from "./components/pages/TermAndCondition";
import Products from "./components/pages/Products";
import ProductDetail from "./components/pages/ProductDetail";
import ErrorPage from "./components/pages/ErrorPage";
import PrivateRoute from "./components/routing/PrivateRoute";
import TabLayout from "./components/pages/Account/TabLayout";
import Wishlist from "./components/pages/Wishlist";
import Checkout from "./components/pages/Checkout";
import BlogDetail from "./components/pages/BlogDetail";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import ShoppingCartPage from "./components/pages/ShoppingCartPage";
import Faq from "./components/pages/Faq";
import ScrollToTop from "./components/include/ScrollToTop";
import { ToastContainer } from "react-toastify";
import Invoice from "./components/pages/Invoice";
import VerifyResetPassword from "./components/pages/VerifyResetPassword";
import ResetPassword from "./components/pages/ResetPassword";
import FlashSalePage from "./components/pages/FlashSalePage";
import ShippingPolicy from "./components/pages/ShippingPolicy";
import ReturnPolicy from "./components/pages/ReturnPolicy";





function Layout() {

  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Hide Header and Footer on the Invoice page
  const isInvoicePage = location.pathname.startsWith("/invoice");

  useEffect(() => {
      // Preloader will stay for 1 second (1000 milliseconds)
      const timer = setTimeout(() => {
          setLoading(false);
      }, 1000); // 1 second delay

      return () => clearTimeout(timer); // Cleanup timer when component unmounts
  }, []);

  

  return (
    <React.Fragment>
      { loading ? (
        <Preloader /> // Show Preloader while loading is true
      ) : (
        <div id="wrapper"> 
            {/* Once loading is false, show the actual content */}
            {!isInvoicePage && <TopNavbar />}
            {!isInvoicePage && <Header />}
            <ScrollToTop />
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="blog" element={<Blog/>} />
            <Route path="blog-detail/:slug" element={<BlogDetail/>}/>
            <Route path="contact" element={<Contact/>} />
            <Route path="faq" element={<Faq/>} />
            <Route path="flashsale" element={<FlashSalePage/>} />
            <Route path="forgot" element={<ForgotPage/>} />
            <Route path="home-search" element={<HomeSearch/>} />
            <Route path="login" element={<LoginPage/>} />
            <Route path="privacy-policy" element={<PrivacyPolicy/>} />
            <Route exact path="product/:categoryId" element={<Products/>}/>
            <Route exact path="product" element={<Products/>}/>
            <Route path="product-detail/:id" element={<ProductDetail/>} />
            <Route path="product/:category/:subCategoryId" element={<Products/>}/>
            <Route path="register" element={<RegisterPage/>} />
            <Route path="reset-password" element={<ResetPassword/>} />
            <Route path="shipping-policy" element={<ShippingPolicy/>} />
            <Route path="terms-conditions" element={<TermAndCondition/>} />
            <Route path="return-policy" element={<ReturnPolicy/>} />
            <Route path="verify-account" element={<VerifyMail/>} />
            <Route path="verify-reset-password" element={<VerifyResetPassword/>} />
            <Route path="/*" element={<ErrorPage />} />


            <Route path='/' element={<PrivateRoute/>}>
                <Route path="account/*" element={<TabLayout/>} />
                <Route path="wishlist" element={<Wishlist/>} />
                <Route path="checkout" element={<Checkout/>} />
                <Route path="view-cart" element={<ShoppingCartPage/>} />
                <Route path="/invoice/:order_id" element={<Invoice/>} />
            </Route>
          </Routes>   
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          {!isInvoicePage && <Footer />}
          <GoTop/>

          {/* Models */}
          <MobileMenu/>
          <CanvaSearch/>
          <Profile/>
          <ShoppingCart />
        </div>
      )}
    </React.Fragment>
  );
}


export default function App(){
  return (
    <Provider store={store}>
      <HashRouter>
        <Layout/>
      </HashRouter>
    </Provider>
  )
}
