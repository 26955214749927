import React from 'react'
import { useSelector } from 'react-redux';

export default function TopNavbar() {

    const {footer} = useSelector((state) => state.setup);
    return (
        <div className="tf-top-bar bg_dark line">
            <div className="container-full px_15 lg-px_40">
                <div className="tf-top-bar_wrap grid-3 gap-30 align-items-center">
                    <div className="tf-top-bar_left">
                        <div className="d-flex gap-30 text_white fw-5 ">
                            <span><i className="fa-solid fa-mobile-screen-button"></i> {footer?.phone}</span>
                            <span><i className="fa-regular fa-envelope"></i> {footer?.email}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}
