import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from '../elements/product/BreadCrumb';
import { NavLink } from 'react-router-dom';
import {BASE_URL, WEB_URL} from '../../gobal/Global';
import {useDispatch, useSelector} from "react-redux";
import {fetchProductDetails, resetProductDetails} from "../../redux/slice/productDetailsSlice";
import Skeleton from 'react-loading-skeleton';
import { Col, Container, Row } from 'react-bootstrap';
import {useAddToCart} from "../../hooks/useAddToCart";
import ShareSocial from '../elements/models/ShareSocial';
import DeliveryReturn from '../elements/models/DeliveryReturn';
import HotDeal from '../elements/home/HotDeal';
import { Helmet } from 'react-helmet';


export default function ProductDetail() {
    const params = useParams();
    const dispatch = useDispatch();
    const { loading, product, relatedProducts, specifications } = useSelector((state) => state.productDetail);
    const { setting } = useSelector((state) => state.setup);
    const { flashSale } = useSelector(state => state.flashSale);
    const {isProductInCartlist,handleCartListToggle} = useAddToCart()
    
    const productUrl = `${WEB_URL}/#/product-detail/${params.id}`; // Replace with your route structure if different

    useEffect(() => {
        dispatch(fetchProductDetails(params.id));
        return () => dispatch(resetProductDetails());
    }, [dispatch, params.id]);
    
    
    // console.log("ProductDetail: ",product);
    return (
        <React.Fragment>
            <Helmet>
                <meta property="og:title" content={product?.seo_title} />
                <meta property="og:description" content={product?.seo_description} />
                <meta property="og:image" content={`${BASE_URL}/${product?.thumb_image}`} />
                <meta property="og:url" content={productUrl} />
            </Helmet>
            <BreadCrumb title={product?.short_name}/>
            {loading ? <Skeleton/>: <React.Fragment></React.Fragment>}

            <section className="flat-spacing-4 pt_0">
                <div className="tf-main-product section-image-zoom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                                <img className="lazyload img-fluid"  data-src={`${BASE_URL + product?.thumb_image}`}
                                     src={`${BASE_URL + "/" + product?.thumb_image}`} alt=""/>
                            </div>
                            <div className="col-md-6">
                                <div className="tf-product-info-wrap position-relative">
                                <div className="tf-zoom-main"></div>
                                    <div className="tf-product-info-list other-image-zoom">
                                        <div className="tf-product-info-title">
                                            <h5>{product?.name}</h5>
                                        </div>
                                        <div className="tf-product-info-badges">
                                            <div className="badges">{product?.is_best === 1 ? "Best Seller" : ""}</div>
                                        </div>
                                        {
                                            product?.is_flash_sale ?
                                            <div className="tf-product-info-price">
                                                <div className="price-on-sale">{setting.currency_icon}{product?.flash_price?.toFixed(2)}</div>
                                                <div className="compare-at-price">{setting.currency_icon}{product?.offer_price?.toFixed(2)}</div>
                                                <div className="badges-on-sale">
                                                    <span>Flash Sale {flashSale?.offer}</span>% OFF
                                                </div>
                                            </div> : 
                                            <div className="tf-product-info-price">
                                                <div className="price-on-sale">{setting.currency_icon}{product?.offer_price?.toFixed(2)}</div>
                                                <div className="compare-at-price">{setting.currency_icon}{product?.price?.toFixed(2)}</div>
                                                <div className="badges-on-sale">
                                                    <span>
                                                        {product?.price && product?.offer_price
                                                        ? Math.round(((product.price - product.offer_price) / product.price) * 100)
                                                        : 0}
                                                    </span>% OFF
                                                </div>
                                            </div>
                                        }
                                        <div className="tf-product-info-liveview">
                                            <p className="fw-6 text-danger">{product?.qty === 0 ? "Out of stock" : ""}</p>
                                        </div>
                                        <div className="tf-product-info-buy-button">
                                            <form className="d-flex w-50 mb-2">
                                                {loading ? <Skeleton width={200}
                                                                     height={40}/> : isProductInCartlist(product.id) ?
                                                    <NavLink onClick={(e) => {
                                                        e.preventDefault();
                                                        handleCartListToggle(product.id)
                                                    }}
                                                             className="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn btn-add-to-cart">
                                                        <span> Remove From Cart </span>
                                                        
                                                    </NavLink> : <NavLink onClick={(e) => {
                                                        e.preventDefault();
                                                        handleCartListToggle(product.id)
                                                    }}
                                                        className="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn btn-add-to-cart">
                                                        <span> Add To Cart </span>
                                                        
                                                    </NavLink>
                                                }
                                            </form>
                                        </div>
                                        <div className="tf-product-info-extra-link">
                                            <NavLink data-bs-target="#delivery_return" data-bs-toggle="modal"
                                                     className="tf-product-extra-icon">
                                                <div className="icon">
                                                    <svg className="d-inline-block" xmlns="http://www.w3.org/2000/svg"
                                                         width="22" height="18" viewBox="0 0 22 18" fill="currentColor">
                                                        <path d="M21.7872 10.4724C21.7872 9.73685 21.5432 9.00864 21.1002 8.4217L18.7221 5.27043C18.2421 4.63481 17.4804 4.25532 16.684 4.25532H14.9787V2.54885C14.9787 1.14111 13.8334 0 12.4255 0H9.95745V1.69779H12.4255C12.8948 1.69779 13.2766 2.07962 13.2766 2.54885V14.5957H8.15145C7.80021 13.6052 6.85421 12.8936 5.74468 12.8936C4.63515 12.8936 3.68915 13.6052 3.33792 14.5957H2.55319C2.08396 14.5957 1.70213 14.2139 1.70213 13.7447V2.54885C1.70213 2.07962 2.08396 1.69779 2.55319 1.69779H9.95745V0H2.55319C1.14528 0 0 1.14111 0 2.54885V13.7447C0 15.1526 1.14528 16.2979 2.55319 16.2979H3.33792C3.68915 17.2884 4.63515 18 5.74468 18C6.85421 18 7.80021 17.2884 8.15145 16.2979H13.423C13.7742 17.2884 14.7202 18 15.8297 18C16.9393 18 17.8853 17.2884 18.2365 16.2979H21.7872V10.4724ZM16.684 5.95745C16.9494 5.95745 17.2034 6.08396 17.3634 6.29574L19.5166 9.14894H14.9787V5.95745H16.684ZM5.74468 16.2979C5.27545 16.2979 4.89362 15.916 4.89362 15.4468C4.89362 14.9776 5.27545 14.5957 5.74468 14.5957C6.21392 14.5957 6.59575 14.9776 6.59575 15.4468C6.59575 15.916 6.21392 16.2979 5.74468 16.2979ZM15.8298 16.2979C15.3606 16.2979 14.9787 15.916 14.9787 15.4468C14.9787 14.9776 15.3606 14.5957 15.8298 14.5957C16.299 14.5957 16.6809 14.9776 16.6809 15.4468C16.6809 15.916 16.299 16.2979 15.8298 16.2979ZM18.2366 14.5957C17.8853 13.6052 16.9393 12.8936 15.8298 12.8936C15.5398 12.8935 15.252 12.943 14.9787 13.04V10.8511H20.0851V14.5957H18.2366Z"></path></svg>
                                                </div>
                                                <div className="text fw-6"> Return Policy</div>
                                            </NavLink>
                                            <NavLink data-bs-target="#share_social" data-bs-toggle="modal" className="tf-product-extra-icon">
                                                <div className="icon">
                                                    <i className="icon-share"></i>
                                                </div>
                                                <div className="text fw-6">Share</div>
                                            </NavLink>
                                        </div>
                                        <div className="tf-product-info-title">
                                            <strong>Short Description</strong>
                                            <p className='text-secondary'>{product?.short_description}</p>
                                        </div>
                                        <div className="tf-product-info-delivery-return">
                                            <div class="widget-content-inner">
                                                <div class="quantity-title fw-6 mb-2">Specifications</div>
                                                <table class="tf-pr-attrs">
                                                    <tbody>
                                                        <tr class="tf-attr-pa-color">
                                                            <th class="tf-attr-label">Weight</th>
                                                            <td class="tf-attr-value">
                                                                <p>{product?.weight} gram</p>
                                                            </td>
                                                        </tr>
                                                        <tr class="tf-attr-pa-size">
                                                            <th class="tf-attr-label">SKU</th>
                                                            <td class="tf-attr-value">
                                                                <p>{product?.sku}</p>
                                                            </td>
                                                        </tr>
                                                        {/* Dynamic Rows for Tags */}
                                                        <tr className="tf-attr-pa-size">
                                                            <th className="tf-attr-label">Tags</th>
                                                            <td className="tf-attr-value">
                                                                {product?.tags 
                                                                    ? Array.isArray(product.tags) 
                                                                        ? product.tags.map(tag => <p key={tag.value}>{tag.value}</p>) 
                                                                        : JSON.parse(product?.tags).map((tag, index) => <span key={index}> {tag?.value},</span>)
                                                                    : <p>No tags available</p>
                                                                }
                                                            </td>
                                                        </tr>

                                                        {/* Dynamic Rows for Specifications */}
                                                        {
                                                            specifications && specifications?.length > 0 ? (
                                                                specifications?.map((spec, index) => (
                                                                    <tr key={`spec-${index}`}>
                                                                        <th>{spec?.key?.key}</th>
                                                                        <td>{spec?.specification}</td>
                                                                    </tr>
                                                                ))
                                                            )  : null
                                                            
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="flat-spacing-17 pt_0">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="widget-tabs style-has-border">
                                <ul className="widget-menu-tab">
                                    <li className="item-title active">
                                        <span className="inner">Description</span>
                                    </li>
                                </ul>
                                <div className="widget-content-tab">
                                    <div className="widget-content-inner active">
                                        <div className="">
                                            <p className="mb_30" dangerouslySetInnerHTML={{ __html: product?.long_description }} ></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ShareSocial 
                productUrl={productUrl} 
                title={product?.short_name} 
                image={product?.thumb_image}
                description={product?.short_description} 
            />
            <DeliveryReturn/>
            {
                relatedProducts.length > 0 ? <HotDeal newArrivalProducts={relatedProducts} title="Related Products"/> : null
            }
            
        </React.Fragment>
    )
}

