import React, { useEffect, useState } from 'react';
import InnerBanner from '../elements/InnerBanner';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesData, fetchProductByCategoryId, fetchProductBySubCategory, fetchProductData } from "../../redux/slice/productSlice";
import { BASE_URL } from "../../gobal/Global";
import { currency } from '../../Currency';
import { useWishlist } from '../../hooks/useWishlist';
import { useAddToCart } from '../../hooks/useAddToCart';
import HotDeal from '../elements/home/HotDeal';
import { Helmet } from 'react-helmet';

export default function Products() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { categoryId, subCategoryId } = useParams();
    const [ currentPage, setCurrentPage] = useState(1); // Track current page

    const { products, seoSetting, loading } = useSelector(state => state.product);
    const { socialLinks, categories} = useSelector((state) => state.setup);
    const { topRatedVisibility, topRatedProducts } = useSelector(state => state.home)
    const { isLoggedIn } = useSelector(state => state.auth);
    const { flashSale } = useSelector(state => state.flashSale);
    const { handleCartListToggle, isProductInCartlist } = useAddToCart(); // No longer needs productId as a parameter
    const { handleWishlistToggle, isProductInWishlist } = useWishlist(isLoggedIn);

    useEffect(() => {
        if (categoryId) {
            dispatch(fetchProductByCategoryId(categoryId));
        } else if (subCategoryId) {
            dispatch(fetchProductBySubCategory(subCategoryId));
        } else {
            dispatch(fetchProductData({ page: currentPage }));
            dispatch(fetchCategoriesData());
        }
    }, [dispatch, categoryId, subCategoryId, currentPage]);



    const handlePageChange = (page) => {
        if (page) {
            setCurrentPage(Number(page));
        }
    };
    

    const handleAddToCart = async (productId) => {
        if (isLoggedIn) {
            await handleCartListToggle(productId);
            // showConfirm(isProductInCartlist(productId) ? "Removed from cart" : "Added to cart");
        } else {
            navigate('/login'); // Redirect to login if not logged in
        }
    };
    // console.log("URL: ",window.location.href);
    return (
        <React.Fragment>
            <Helmet>
            <meta property="og:title" content={seoSetting?.page_name} />
                <meta property="og:description" content={seoSetting?.seo_description} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <InnerBanner title="Products"/>

            <section className="flat-spacing-1">
                <div className="container">
                    {/* Shop Controls */}
                    <div className="tf-shop-control grid-3 align-items-center">
                        <div className="tf-control-filter">
                            {/* <NavLink to="#filterShop" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft" className="tf-btn-filter"><span className="icon icon-filter"></span><span className="text">Filter</span></NavLink>  */}
                        </div>
                    </div>

                    <div className="tf-row-flex">
                        <aside className="tf-shop-sidebar wrap-sidebar-mobile">
                            <div className="widget-facet wd-categories">
                                <div className="facet-title" data-bs-target="#categories" data-bs-toggle="collapse" aria-expanded="true" aria-controls="categories">
                                    <span>Product categories</span>
                                    <span className="icon icon-arrow-up"></span>
                                </div>
                                <div id="categories" className="collapse show">
                                    <ul className="list-categoris current-scrollbar mb_36"> 
                                        {categories.map(category => {
                                            // Calculate the total product count by summing up the counts of active subcategories
                                            const totalProductCount = category.active_sub_categories.reduce(
                                                (sum, subCategory) => sum + (subCategory.products_count || 0),0
                                            );
                                            return (
                                                <li key={category.slug} className="cate-item current">
                                                    <NavLink to={`/product/${category.id}`}>
                                                        <span>{category.name}</span>&nbsp;<span>({totalProductCount})</span>
                                                    </NavLink>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="widget-facet">
                                <div className="facet-title" data-bs-target="#gallery" data-bs-toggle="collapse" aria-expanded="true" aria-controls="gallery">
                                    <span>Gallery</span>
                                    <span className="icon icon-arrow-up"></span>
                                </div>
                                <div id="gallery" className="collapse show">
                                    <div className="grid-3 gap-4 mb_36">
                                        {products?.data?.map(data => (
                                            <NavLink key={data?.slug} to={`/product-detail/${data?.slug}`} className="item-gallery">
                                                <img className="lazyload " data-src={`${BASE_URL + "/" + data.thumb_image}`} src={`${BASE_URL + "/" + data.thumb_image}`} alt="img-gallery" />
                                                
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="widget-facet">
                                <div className="facet-title" data-bs-target="#follow" data-bs-toggle="collapse" aria-expanded="true" aria-controls="follow">
                                    <span>Follow us</span>
                                    <span className="icon icon-arrow-up"></span>
                                </div>
                                <div id="follow" className="collapse show">
                                    <ul className="tf-social-icon d-flex gap-10">
                                    {
                                        socialLinks.map((data, index) => (
                                            <li key={`social_link-${index}`}><NavLink to={data.link} className="box-icon w_34 round social-facebook social-line" target='_blank'><i className={data.icon}></i></NavLink></li>
                                        ))
                                    }
                                    </ul>
                                </div>
                            </div>
                        </aside>

                        <div className="tf-shop-content">
                            <div className="grid-layout wrapper-shop" data-grid="grid-3">
                                {loading ? <div>Loading...</div> : products?.data?.map(data => {
                                    const inCart = isProductInCartlist(data.id);
                                    const inWishlist = isProductInWishlist(data.id); // Check if product is in wishlist
                                    return (
                                        <div key={data.id} className="card-product">
                                            <div className="card-product-wrapper">
                                                <NavLink to={`/product-detail/${data?.slug}`} className="product-img">
                                                    <img className="lazyload img-product" data-src={`${BASE_URL + "/" + data.thumb_image}`} src={`${BASE_URL + "/" + data.thumb_image}`} alt="image-product" />
                                                    <img className="lazyload img-hover" data-src={`${BASE_URL + "/" + data.thumb_image}`} src={`${BASE_URL + "/" + data.thumb_image}`} alt="image-product" />
                                                </NavLink>

                                                <div className="list-product-btn absolute-2">
                                                    {/* button for add or remove from cart */}
                                                    <button onClick={() => handleAddToCart(data.id)} className="box-icon bg_white quick-add tf-btn-loading">
                                                        <span className={inCart ? "icon icon-delete" : "icon icon-bag"}></span>
                                                        <span className="tooltip">{inCart ? "Remove from cart" : "Add to cart"}</span>
                                                    </button>

                                                    {/* button for add or remove from wishlist  */}
                                                    <button onClick={() => handleWishlistToggle(data.id)} className="box-icon bg_white wishlist tf-btn-loading">
                                                        <span className={inWishlist ? "icon icon-delete" : "icon icon-heart"}></span>
                                                        <span className="tooltip">{inWishlist ? "Remove from Wishlist" : "Add to Wishlist"}</span>
                                                    </button>
                                                </div>
                                                {
                                                    data?.is_flash_sale ? 
                                                    <div class="on-sale-wrap text-end">
                                                        <div class="on-sale-item pre-order bg-danger">Flash Sale {flashSale?.offer}%</div>
                                                    </div> : null
                                                }
                                            </div>
                                            <div className="card-product-info">
                                                <NavLink to={`/product-detail/${data?.slug}`} className="title link">{data.name}</NavLink>
                                                <span className="price">Mrp: {currency.icon}{data.price}</span>
                                                <span className="price">
                                                {
                                                   data?.is_flash_sale ?  `${currency.icon} ${data?.flash_price.toFixed(2)}` : `${currency.icon} ${data.offer_price}`
                                                }
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {/* pagination */}
                            {/* <ul className="tf-pagination-wrap tf-pagination-list">
                                <li className="active">
                                    <NavLink to="#" className="pagination-link">1</NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="pagination-link animate-hover-btn">2</NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="pagination-link animate-hover-btn">3</NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="pagination-link animate-hover-btn">4</NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="pagination-link animate-hover-btn">
                                        <span className="icon icon-arrow-right"></span>
                                    </NavLink>
                                </li>
                            </ul> */}
                            <ul className="tf-pagination-wrap tf-pagination-list">
                                {products?.links?.map((link, index) => (
                                    <li
                                        key={index}
                                        className={link.active ? "active" : ""}
                                        onClick={() =>
                                            link.url && handlePageChange(new URL(link.url).searchParams.get("page"))
                                        }
                                    >
                                        <NavLink to="#" className="pagination-link">
                                            {link.label.replace("&laquo;", "«").replace("&raquo;", "»")}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
            {
                topRatedVisibility ? <HotDeal newArrivalProducts={topRatedProducts} title="Top Products"/> : null
            }
            

        </React.Fragment>
    );
}
