import React from 'react'
import { 
    FacebookShareButton, 
    OKShareButton, 
    PinterestShareButton, 
    TwitterShareButton, 
    WhatsappShareButton 
} from 'react-share';

import { BASE_URL } from '../../../gobal/Global';

export default function ShareSocial({ productUrl, title, image, description }) {

    const currentUrl = window.location.href;

    // console.log({productUrl, title, image, description, currentUrl});
    return (
        <div className="modal modalCentered fade modalDemo tf-product-modal modal-part-content" id="share_social">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="header">
                        <div className="demo-title">Share</div>
                        <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                    </div>
                    <div className="overflow-y-auto">
                        <ul className="tf-social-icon d-flex gap-10">
                            <li className="box-icon social-facebook bg_line">
                                <FacebookShareButton
                                    url={currentUrl}
                                    title={title}
                                    hashtag="#AmazingProduct"
                                >
                                    <i className="icon icon-fb"></i>
                                </FacebookShareButton>
                            </li>
                            <li className="box-icon social-twiter bg_line">
                                <TwitterShareButton
                                    url={currentUrl}
                                    title={title}
                                >
                                    <i className="icon icon-Icon-x"></i>
                                </TwitterShareButton>
                            </li>
                            <li className="box-icon social-pinterest bg_line">
                                <PinterestShareButton
                                    url={currentUrl}
                                    media={`${BASE_URL}/${image}`}
                                    description={description}
                                >
                                    <i className="icon icon-pinterest-1"></i>
                                </PinterestShareButton>
                            </li>
                            <li className="box-icon social-whatsapp bg_line">
                                <WhatsappShareButton
                                    url={productUrl}
                                    title={title}
                                    separator=" - "
                                >
                                    <i className="icon icon-whatsapp"></i>
                                </WhatsappShareButton>
                            </li>
                        </ul>

                        <form className="form-share" method="post" acceptCharset="utf-8">
                            <fieldset>
                                {/* Display the shareable text for preview */}
                                <input type="text" value={productUrl} tabIndex="0" aria-required="true"/>
                            </fieldset>
                            <div className="button-submit">
                                {/* Button to copy the shareable text */}
                                <button
                                    className="tf-btn btn-sm radius-3 btn-fill btn-icon animate-hover-btn"
                                    type="button"
                                    onClick={() => {
                                        const shareText = `Check out this amazing product!\n\n${title}\n${description}\nLink: ${productUrl}`;
                                        navigator.clipboard.writeText(shareText)
                                            .then(() => alert("Shareable link copied to clipboard!"))
                                            .catch((err) => alert("Failed to copy: " + err));
                                    }}
                                >
                                    Copy
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}
